import { createFocusTrap } from 'focus-trap'

export default () => ({
    isActive: false,

    close() {
        this.isActive = false
    },

    toggle() {
        this.isActive = !this.isActive
    },

    onKeyUp(event) {
        if (event.key === 'Escape') {
            this.close()
        }
    },

    onClickOutside(event) {
        if (!this.$el.contains(event.target)) {
            this.close()
        }
    },

    init() {
        const boundOnKeyUp = this.onKeyUp.bind(this)
        const boundOnClickOutside = this.onClickOutside.bind(this)
        const focusTrap = createFocusTrap(this.$el, {
            clickOutsideDeactivates: true,
        })

        this.$watch('isActive', (isActive) => {
            if (isActive) {
                window.addEventListener('keyup', boundOnKeyUp)
                window.addEventListener('click', boundOnClickOutside)
                focusTrap.activate()
            } else {
                window.removeEventListener('keyup', boundOnKeyUp)
                window.removeEventListener('click', boundOnClickOutside)
                focusTrap.deactivate()
            }
        })
    },
})
