import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { createFocusTrap } from 'focus-trap'

const mobileNavigationBreakpoint = 1024

export default () => ({
    isActive: false,

    close() {
        this.isActive = false
    },

    toggle() {
        this.isActive = !this.isActive
    },

    onResize() {
        if (window.innerWidth > mobileNavigationBreakpoint) {
            this.close()
        }
    },

    boundOnKeyUp(event) {
        if (event.key === 'Escape') {
            this.close()
        }
    },

    init() {
        const boundOnResize = this.onResize.bind(this)
        const boundOnKeyUp = this.boundOnKeyUp.bind(this)
        const focusTrap = createFocusTrap(this.$el, {
            clickOutsideDeactivates: true,
        })

        this.$watch('isActive', (isActive) => {
            if (isActive) {
                window.addEventListener('resize', boundOnResize)
                window.addEventListener('keyup', boundOnKeyUp)
                disableBodyScroll(this.$refs.panel, {
                    reserveScrollBarGap: true,
                })
                focusTrap.activate()
            } else {
                window.removeEventListener('resize', boundOnResize)
                window.removeEventListener('keyup', boundOnKeyUp)
                enableBodyScroll(this.$refs.panel)
                focusTrap.deactivate()
            }
        })
    },
})
